@import '../../../styles/customMediaQueries.css';
.locationSection {
  background-color: var(--colorText);
  padding: 50px 0px 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 0;

  & .contentWidth {
    &.smallCard {
      & .carouselWrapper {
        & :global(.slick-slide) {
          max-width: 100%;
          @media (--viewportMedium) {
            max-width: 345px;
            width: 100% !important;
          }
        }
      }
    }
  }
  & h2 {
    line-height: 120%;
    width: 100%;
    color: var(--marketplaceColorDark);
    font-size: 30px;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 25px 0;
    @media (--viewportMedium) {
      font-size: 35px;
      margin: 0 0 35px 0;
    }
    @media (--viewportLarge) {
      margin: 60px 0 60px 0;
    }
  }
  & h1 {
    width: 100%;
    color: var(--marketplaceColorDark);
    font-size: 30px;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 20px 0;
    @media (--viewportMedium) {
      font-size: 35px;
    }
    @media (--viewportLarge) {
      font-size: 40px;
    }
  }
  & .imgDiv {
    position: relative;
    padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
    margin-top: 50px;
    border-radius: 15px;
    overflow: hidden;
    & img {
      margin-top: 0px !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
    }
  }
  & h3 {
    line-height: 100%;
    width: 100%;
    text-align: left;
    color: var(--marketplaceColorDark);
    font-size: 30px;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 15px 0;
    @media (--viewportMedium) {
      font-size: 35px;
    }
    @media (--viewportLarge) {
      font-size: 36px;
    }
  }
  & p {
    font-size: 14px;
    line-height: 140%;
    text-align: justify !important;
    font-weight: var(--fontWeightRegular);
    margin: 0;
    padding: 0;
    color: #51565c;
    text-transform: none;
  }
  & .contentWidth {
    position: relative;
    & .sectionImages {
      /* min-height: 360px; */
      margin-top: 50px;
      border-radius: 4px;
    }
    & .carouselWrapper {
      position: relative;

      & .leftArrow,
      & .rightArrow {
        background-color: var(--marketplaceColor);
        display: inline-flex;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: var(--transitionStyle);
        padding: 4px;
        z-index: 10;
        &:hover {
          transition: var(--transitionStyle);
        }
        & .leftArrowIcon,
        & .rightArrowIcon {
          color: white;
          font-size: 80px;
          line-height: 20%;
          margin-top: -9px;
          margin-left: -7px;
        }
        & > img {
          max-width: 100%;
          margin-top: -3px;
        }
      }
      & .leftArrow {
        left: 0px;
        @media (max-width: 767px) {
          display: none;
        }
        @media (--viewportLarge) {
          left: -80px;
        }
      }
      & .rightArrow {
        right: 0px;
        @media (max-width: 767px) {
          display: none;
        }
        @media (--viewportLarge) {
          right: -80px;
        }
        & .rightArrowIcon {
          margin-left: 3px;
        }
      }
      & :global(.slick-slider) {
        padding: 20px 10px;
        background-color: var(--marketplaceColor);
      }
      & :global(.custom-arrow) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: none;
        @media (--viewportMLarge) {
          display: block;
        }
      }

      & :global(.custom-arrow.prev) {
        left: -70px;
      }
      & :global(.custom-arrow.next) {
        right: -70px;
      }
      & :global(.slick-list) {
        background-color: var(--marketplaceColor);
      }
      & :global(.slick-slide) {
        height: auto;
        padding: 0 10px;
      }
      & :global(.slick-slide > div) {
        background-color: var(--matterColorBright);
        border-radius: 20px;
        height: 100%;
      }

      & :global(.slick-track) {
        display: flex;
      }
      & :global(.slick-dots) {
        position: relative;
        top: 8px;
      }
    }
  }
}
.fixedWidthContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: justify;
}
