@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--colorText);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (max-width: 768px) {
    position: relative;
    top: 94px;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.anchor {
  display: block;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
}

.card {
  composes: h5 from global;
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  width: 200px;
  min-height: 120px;

  /* Font */
  color: var(--colorGrey700);

  background-color: var(--colorText);

  /* Dimensions */
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    width: 250px;
    margin-top: 0;
    margin-bottom: 0;
  }
  & > div {
    &:first-child {
      & :global(.slick-slider) {
        & :global(.slick-list) {
          height: 160px !important;
          & :global(.slick-track) {
            height: 100%;

            & :global(.slick-slide) {
              display: flex;
              & div {
                height: 100%;
                width: 100%;
                & img {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Overwrite Mapbox's specific font rules */
.root .card {
  /* font-weight: var(--fontWeightSemiBold); */
}

.aspectratioWrapper {
  background: var(--colorGrey100); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBlack);
  font-size: 20px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
  & .title {
    font-weight: var(--fontWeightBlack);
    font-size: 20px;
    line-height: 120%;
    color: #51565c;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
    width: calc(100% - 20px);
    font-family: var(--fontFamily);
  }
  & .flag {
    width: 20px;
    & > img {
      max-width: 100%;
      width: 20px;
      height: auto;
      opacity: 0.8;
    }
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: #51565c;
  font-size: 13px;
  padding: 0;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.amenities {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  & img {
    width: calc(100% / 6);
    @media (--viewportMedium) {
      width: calc(100% / 7.8);
    }
    &:not(:last-child) {
      margin-right: 6px;
      @media (--viewportMedium) {
        margin-right: 10px;
      }
    }
  }
}
.priceSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--fontFamily);
  margin-bottom: 5px;
  & > p {
    margin: 0;
    padding: 0;
    color: var(--colorTextDark);
    font-size: 12.9px;
    line-height: 110%;
  }
  & .price {
    color: var(--marketplaceColorLight);
    font-size: 20.7px;
    margin: 0;
    font-weight: var(--fontWeightBold);
  }
}
.name {
  flex-grow: 1;
}

.paginationInfo {
  background-color: var(--marketplaceColor);
  color: var(--colorText);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.paginationPage {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.paginationPrev {
  display: inline-block;
  width: 36px;
  height: 40px;
  border: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>');
  background-position: center;
  border-bottom-left-radius: inherit;
  cursor: pointer;
  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.paginationNext {
  display: inline-block;
  width: 36px;
  height: 40px;
  border: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>');
  background-position: center;
  border-bottom-right-radius: inherit;
  cursor: pointer;

  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same bg-color and border as label */
  background-color: var(--colorText);
  border-right-style: solid;
  border-right-color: var(--colorGrey100);
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--colorGrey100);
  border-bottom-width: 1px;
}

.caretWithCarousel {
  background-color: var(--marketplaceColor);
  border-right-color: var(--marketplaceColor);
  border-bottom-color: var(--marketplaceColor);
}

.borderRadiusInheritTop {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.borderRadiusInheritBottom {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.imgPills {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  & > span {
    background-color: var(--colorText);
    color: var(--colorTextDark);
    font-size: 14px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 100px;
    font-family: 'Alegreya Sans' !important;
  }
}

.pills {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
  & > span {
    background-color: var(--marketplaceColorLight);
    color: var(--colorText);
    font-size: 14px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 100px;
    font-family: 'Alegreya Sans' !important;
    /* font-weight: 100; */
  }
}
