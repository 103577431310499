@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-direction: column;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mainContentWrapper {
  & .sectionHero {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 72px);
    overflow: hidden;
    text-align: center;
    & > img {
      position: relative;
      z-index: 0;
      max-width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    & > h2 {
      position: absolute;
      z-index: 1;
      bottom: 10px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #f6fde8;
      font-size: 60px;
      font-weight: var(--fontWeightBlack);
      padding: 20px 24px;
      margin: 0 0 0 0;
      @media (max-width: 767px) {
        line-height: 40px;
        bottom: 100px;
        font-size: 40px;
      }
      @media (--viewportMedium) {
        padding: 20px 24px;
        font-size: 70px;
        bottom: 15px;
      }
      @media (--viewportLarge) {
        padding: 30px 24px;
        font-size: 80px;
        bottom: 20px;
      }
    }
  }
  & .seoCategory {
    width: 100%;
    text-align: center;
    padding: 50px 24px;
    background-color: var(--colorText);

    & .fixedWidthContainer {
      text-align: center;
    }
    & h2 {
      width: 100%;
      text-align: left;
      color: var(--marketplaceColor);
      font-size: 30px;
      line-height: 110%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 15px 0;
      @media (max-width: 767px) {
        line-height: 28px;
      }

      @media (--viewportMedium) {
        font-size: 35px;
      }
    }
    & p {
      font-size: 14px;
      line-height: 150%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: justify;
      color: #51565c;
      text-transform: none;
      @media (--viewportMedium) {
        font-size: 15px;
      }
    }
    & .mapWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      margin-top: 50px;
      margin: 0 auto;
      & p {
        margin: 0;
        padding: 0;
        color: var(--colorTextDark);
        font-size: 12.9px;
        line-height: 110%;
        text-align: left;
      }

      & .searchResultContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @media (--viewportMedium) {
          width: 100%;
        }
        & .listings {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding: 0;
        }
      }
      & .clusterMapWrapperDesktop {
        width: 100%;
        height: 50vh;
        align-self: flex-start;
        /* display: none; */
        @media (--viewportMedium) {
          display: block;
          height: 60vh;
        }
        @media (--viewportLarge) {
          height: 70vh;
        }
      }
    }
  }
  & .findPitcheSec {
    margin-top: 70px;
    & h2 {
      text-align: center;
      width: 100%;
      color: var(--marketplaceColor);
      font-size: 30px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      margin: 0 0 25px 0;
      @media (max-width: 767px) {
        padding: 2px 9px;
      }

      @media (--viewportMedium) {
        margin: 0 0 50px 0;
        font-size: 35px;
      }
      @media (--viewportLarge) {
        margin: 0 0 60px 0;
        font-size: 36px;
      }
    }
  }
  & .findCountrySec {
    padding: 50px 0px;
    @media (--viewportMedium) {
      padding: 50px 24px;
    }

    & .locationsWidth {
      text-align: left;
      & .findCountryLocation {
      }
      & > h2 {
        color: var(--colorText);
        font-size: 35px;
        font-weight: var(--fontWeightBlack);
        line-height: 100%;
        padding: 0 24px;
        margin: 0 0 15px 0;
        @media (--viewportMedium) {
          padding: 0;
        }
      }
      & > p {
        color: var(--colorText);
        font-size: 15px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        padding: 0;
        max-width: 50%;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        text-align: justify;
      }
    }
  }
  & .newsletterSec {
  }
  & .otherSeoCategorySec {
    padding-bottom: 50px;
    & .contentWidth {
      margin-bottom: 0;
      & > h3 {
        margin-bottom: 50px;
      }
    }
    & .otherSeoCategory {
    }
  }
}
.showGeneralAmenities {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 15px auto 0;
  overflow-x: auto;
  @media (--viewportMedium) {
    padding: 0 24px;
  }
  @media (--viewportLarge) {
    justify-content: center;
  }
  & .amenityBlock {
    text-align: center;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 6);
    min-width: calc(100% / 2);
    padding: 0 10px 10px;
    @media (min-width: 480px) {
      min-width: calc(100% / 3);
    }
    @media (--viewportSmall) {
      min-width: calc(100% / 4);
    }
    @media (--viewportMedium) {
      min-width: calc(100% / 5);
    }
    @media (--viewportLarge) {
      min-width: auto;
    }

    & > img {
      max-width: 100%;
      width: 100px;
      @media (--viewportLarge) {
        width: 150px;
      }
    }
    & > p {
      margin: 0;
      text-align: justify;
      padding: 0;
      font-size: 14px;
      line-height: 120%;
      color: var(--colorText);
      font-weight: var(--fontWeightBold);
      @media (--viewportLarge) {
        font-size: 18px;
      }
    }
  }
}
.popularAmenities {
  padding: 40px 24px 0;
}
.popularSearchLabel {
  color: var(--colorText);
  font-size: 35px;
  text-align: left;
  line-height: 130%;
  font-weight: var(--fontWeightBlack);
  margin: 0px 0 10px 0;
  padding: 0;
  @media (--viewportMedium) {
    text-align: center;
  }
}
.showAllBtn {
  background-color: var(--marketplaceColorDark);
  color: var(--colorText);
  transition: var(--transitionStyle);
  padding: 10px 50px;
  border-radius: 100px;
  display: inline-block;
  font-size: 20px;
  margin-top: 10px;
  &:hover {
    text-decoration: none;
    transition: var(--transitionStyle);
    color: var(--colorText);
    background-color: var(--marketplaceColor);
  }
}
.countryWrapper,
.mapWrapper,
.amenityWrapper {
  position: relative;
  z-index: 0;
}
.countryWrapper {
  & :global(.slick-slider) {
    @media (max-width: 767px) {
      padding-bottom: 20px;
    }
  }
  & :global(.slick-slide) {
    padding: 0 10px;
  }
  & :global(.slick-dots) {
    bottom: 0;
  }
}
.amenityWrapper {
  & :global(.swiper-slide) {
    border-radius: 14px;
    @media (max-width: 1280px) {
      background-color: var(--colorText);
    }
  }
  & :global(.slick-dots) {
    bottom: 0;
  }
}

.fullSliderSection {
  background-color: var(--colorText);
  padding: 50px 0px;
  overflow-x: hidden;
  @media (--viewportMedium) {
    padding: 50px 0px;
  }
  & :global(.slick-slider) {
    margin: 0 -10px;
    @media (--viewportLarge) {
      margin: 0 -15px;
    }
    & :global(.slick-slider) {
      margin: 0;

      & :global(.slick-slide) {
        padding: 0;

        & :global(.slick-arrow) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 100px;
          background-color: var(--colorText);
          z-index: 1;
          box-shadow: var(--boxShadow);
          transition: var(--transitionStyle);
          &:hover {
            transition: var(--transitionStyle);
            transform: scale(1.2);
          }
        }
        & :global(.slick-arrow.slick-disabled) {
          opacity: 0.5;
          cursor: not-allowed;
        }

        & :global(.slick-prev) {
          left: 0px;
        }
        & :global(.slick-prev:before) {
          content: ' ';
          background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTYuNzMxNzcgMTAuNjQwMkM2LjM3ODIxIDEwLjIxNTkgNi40MzU1MyA5LjU4NTM3IDYuODU5ODEgOS4yMzE4QzcuMjg0MDkgOC44NzgyNCA3LjkxNDY1IDguOTM1NTYgOC4yNjgyMiA5LjM1OTg0TDEzLjI2ODIgMTUuMzU5OEMxMy42MjE4IDE1Ljc4NDEgMTMuNTY0NSAxNi40MTQ3IDEzLjE0MDIgMTYuNzY4MkMxMi43MTU5IDE3LjEyMTggMTIuMDg1MyAxNy4wNjQ1IDExLjczMTggMTYuNjQwMkw2LjczMTc3IDEwLjY0MDJaIiBmaWxsPSIjMDAwMDAwIi8+IDxwYXRoIGQ9Ik04LjI2ODIyIDEwLjY0MDJDNy45MTQ2NSAxMS4wNjQ1IDcuMjg0MDkgMTEuMTIxOCA2Ljg1OTgxIDEwLjc2ODJDNi40MzU1MyAxMC40MTQ3IDYuMzc4MjEgOS43ODQxMiA2LjczMTc3IDkuMzU5ODRMMTEuNzMxOCAzLjM1OTg0QzEyLjA4NTMgMi45MzU1NiAxMi43MTU5IDIuODc4MjQgMTMuMTQwMiAzLjIzMThDMTMuNTY0NSAzLjU4NTM3IDEzLjYyMTggNC4yMTU5MyAxMy4yNjgyIDQuNjQwMjFMOC4yNjgyMiAxMC42NDAyWiIgZmlsbD0iIzAwMDAwMCIvPiA8L2c+Cg08L3N2Zz4=);
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          display: inline-block;
          background-size: 100%;
        }
        & :global(.slick-next) {
          right: 0px;
        }
        & :global(.slick-next:before) {
          content: ' ';
          background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB0cmFuc2Zvcm09InJvdGF0ZSgxODApIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTYuNzMxNzcgMTAuNjQwMkM2LjM3ODIxIDEwLjIxNTkgNi40MzU1MyA5LjU4NTM3IDYuODU5ODEgOS4yMzE4QzcuMjg0MDkgOC44NzgyNCA3LjkxNDY1IDguOTM1NTYgOC4yNjgyMiA5LjM1OTg0TDEzLjI2ODIgMTUuMzU5OEMxMy42MjE4IDE1Ljc4NDEgMTMuNTY0NSAxNi40MTQ3IDEzLjE0MDIgMTYuNzY4MkMxMi43MTU5IDE3LjEyMTggMTIuMDg1MyAxNy4wNjQ1IDExLjczMTggMTYuNjQwMkw2LjczMTc3IDEwLjY0MDJaIiBmaWxsPSIjMDAwMDAwIi8+IDxwYXRoIGQ9Ik04LjI2ODIyIDEwLjY0MDJDNy45MTQ2NSAxMS4wNjQ1IDcuMjg0MDkgMTEuMTIxOCA2Ljg1OTgxIDEwLjc2ODJDNi40MzU1MyAxMC40MTQ3IDYuMzc4MjEgOS43ODQxMiA2LjczMTc3IDkuMzU5ODRMMTEuNzMxOCAzLjM1OTg0QzEyLjA4NTMgMi45MzU1NiAxMi43MTU5IDIuODc4MjQgMTMuMTQwMiAzLjIzMThDMTMuNTY0NSAzLjU4NTM3IDEzLjYyMTggNC4yMTU5MyAxMy4yNjgyIDQuNjQwMjFMOC4yNjgyMiAxMC42NDAyWiIgZmlsbD0iIzAwMDAwMCIvPiA8L2c+Cg08L3N2Zz4=);
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          display: inline-block;
          background-size: 100%;
        }
      }
    }
  }
  & :global(.slick-slide) {
    padding: 10px 10px;
  }
  & :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 100px;
  }

  & :global(.slick-arrow.slick-disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }
  & :global(.slick-prev) {
    left: 30px;
  }
  & :global(.slick-next) {
    right: 30px;
  }

  & :global(.slick-prev:before),
  & :global(.slick-next:before) {
    display: none;
  }
  & .popularSearchLabel {
    color: var(--marketplaceColorDark);
  }
  & .showGeneralAmenities {
    & .amenityBlock {
      & > p {
        text-align: justify;
        color: var(--marketplaceColorDark);
      }
    }
  }
}
.loaderDiv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 72px);
  & .loader {
  }
}

.listingsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.mapListingSec {
  padding: 50px 0px;
  @media (--viewportMedium) {
    padding: 50px 24px;
  }
  & .fullSliderSection {
    background-color: transparent;
    padding: 0;
    margin-bottom: 15px;
  }
}
.mapListings {
  composes: fixedWidthContainer;
  & .mapListingsCards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-bottom: 25px;

    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--viewportLarge) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.newsletterSection {
  background-image: url('https://dih6yo2fd8n78.cloudfront.net/5.+Newsletter-min.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100dvh;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 24px;
  @media (max-width: 768px) {
    background-image: url('https://dih6yo2fd8n78.cloudfront.net/5.+Newsletter-min.jpg');
  }
  @media (--viewportMedium) {
    padding: 50px 24px;
  }
}
.regionHighLightsSec {
  background-color: var(--colorText);
  padding: 0px 24px 50px;
}
.discoverSec {
  padding: 50px 24px;
  & .popularAmenities {
    padding: 0;
    & .popularSearchLabel {
      margin-bottom: 0;
    }
    & .showGeneralAmenities {
      margin-top: 0;
    }
  }
}

.map {
  width: 100%;
}

.listingsWrapper {
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.clusterMapWrapperMobile {
  position: fixed;
  top: 0;
  width: 100vh;
  height: 100vh;
  z-index: 20;

  @media (min-width: 768px) {
    display: none;
  }
}

.closeButtonWrapper {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  top: 2%;
  left: 0;
  z-index: 21;
  padding: 0 5%;
  cursor: pointer;
  @media (min-width: 768px) {
    display: none;
  }
}

.closeIcon {
  transform: scale(2);
}

.containerListingsAndMap {
  width: 100%;
}
.contentWidth {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  @media (--viewportMLarge) {
    max-width: 80%;
  }
}
.fixedWidthContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
}
.moreListingsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  & > button {
    background-color: var(--marketplaceColorDark);
    border-color: var(--marketplaceColorDark);
    color: var(--colorText);
    padding: 15px 30px;
    border-radius: 100px;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    line-height: 120%;
    width: auto;
    min-width: 200px;
    min-height: auto;
    @media (--viewportMedium) {
      font-size: 20px;
    }
  }
}
