@import '../../../styles/customMediaQueries.css';

.countrySec {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 24px;
  @media (--viewportMedium) {
    overflow-x: visible;
    display: grid;
    padding: 0;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (--viewportMLarge) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.countryBlock {
  text-align: center;
  border-radius: 20px;
  height: 50vw;
  background-color: var(--colorTextDark);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-width: 250px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 15px;
  }

  @media (--viewportSmall) {
    height: 30vw;
  }
  @media (--viewportMedium) {
    height: 20vw;
    margin-right: 0px;
    min-width: auto;
  }
  @media (--viewportLargeWithPaddings) {
    height: 10vw;
  }

  @media (max-width: 1280px) {
    width: 100%;
    height: 250px;
  }
  &::before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorTextDark);
    opacity: 0.2;
    content: '';
    top: 0;
    left: 0;
  }
  & > img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  & > span {
    color: var(--colorText);
    font-size: 30px;
    line-height: 130%;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 10px 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: 0px 0px 8px #000000a3;
  }
}
